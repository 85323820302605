<template>
    <div class="about-page">
        <div class="banner vivify fadeIn" v-if="bannerList.length>0">
            <img :src="bannerList[0].banner">
        </div>
        <div class="mt-100 mt-0">
           <div class="contant1 mt-0 pt-0">
               <div class="title-text mt-0">
                   <div class="text1">{{  $t('about.profile') }}</div>
               </div>
               <div>
                  <div class="text2">
                      <span style="font-weight: 600">{{ $store.state.fromObj.title }}</span>
                  </div>
                   <div class="text2 mt-30 mt-0">
                       <span>{{ $store.state.fromObj.brief }}</span>
                  </div>
               </div>
           </div>
            <div class="contant1 mt-100 mt-0 pt-0">
                <div class="title-text d-n">
                    <div class="text1" style="color:#FFFFFF;"></div>
                </div>
                <div class="flex-between box1 ">
                    <div class="textBox">
                        <div class="value">{{ $store.state.fromObj.establishment }}</div>
                        <div class="title">{{  $t('about.period') }}</div>
                    </div>
                    <div class="textBox">
                        <div class="value">{{ $store.state.fromObj.cooperative }}</div>
                        <div class="title">{{  $t('about.customer') }}</div>
                    </div>
                    <div class="textBox">
                        <div class="value">{{ $store.state.fromObj.service }}</div>
                        <div class="title">{{  $t('about.team') }}</div>
                    </div>
                </div>
          </div>
          <div class="contant1 mt-30 mt-0 pt-0">
              <div class="title-text d-n">
                  <div class="text1" style="color:#FFFFFF;"></div>
              </div>
              <div class="box3 mt-20">
                  <div class="video-container">
                      <video
                          ref="myVideo"
                          oncontextmenu="return false"
                          controlslist="nodownload"
                          class="video"
                          autoplay
                          controls
                          :poster="$store.state.fromObj.video_icon"
                      >
                          <source :src="$store.state.fromObj.video" type="video/mp4">
                      </video>
                      <!--              <button class="play-button"  @click="handlePlay"></button>-->
                  </div>
<!--                  <img class="image2" src="./img/image1.png"/>-->
              </div>
          </div>
          <div class="mt-100 mt-0 bc-48 pt-0 pb-20">
              <div class="contant1 mt-0">
                  <div class="title-text c-ff">
                      <div class="text1 c-ff">{{  $t('about.certification') }}</div>
                  </div>
                  <div class="text2 mt-30 mt-0 c-ff mb-10">
                     <span>{{ $store.state.fromObj.certification }}</span>
                  </div>
              </div>
              <div class="contant1 mt-30 mt-0">
                  <div class="title-text d-n">
                      <div class="text1" style="color:#001748;"></div>
                  </div>
                  <div class="flex-between box3 f-w">
                      <div  v-for="(item,index) in $store.state.fromObj.certification_img">
                          <img class="image3" :src="item.url"/>
                      </div>
                  </div>
              </div>
          </div>
          <div class="bc-f8 pt-0 pb-0" style="overflow: hidden">
              <div class="contant1 mt-30 mt-0">
                  <div class="title-text">
                      <div class="text1">{{  $t('about.capacity') }}</div>
                  </div>
                  <div>
                      <div class="text2 mt-30 mt-0 c-33">
                          <span>{{ $store.state.fromObj.capacity }}</span>
                      </div>
                  </div>
              </div>
              <div class="contant1 mt-30 mt-0">
                  <div class="title-text d-n">
                     <div class="text1" style="color:#FFFFFF;"></div>
                  </div>
                  <div class="box3 mt-20">
                     <img class="image2" :src="$store.state.fromObj.capacity_img[0].url"/>
                  </div>
              </div>
          </div>
      <div class="mt-100 mt-0 ">
        <div class="contant1 mt-0">
          <div class="title-text">
            <div class="text1">{{  $t('about.cooperation') }}</div>
          </div>
          <div class="text2 mb-10">
            <span>{{ $store.state.fromObj.branding }}</span>
          </div>
        </div>
        <div class="contant1 mt-30 mt-0 pb-100 pb-20">
          <div class="title-text d-n">
            <div class="text1" style="color:#ffffff;"></div>
          </div>
          <div class="flex-between box3 f-w">
              <div  v-for="(item,index) in $store.state.fromObj.branding_img">
                  <img class="image4" :src="item.url"/>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
  import '../assets/css/common.css'
  import '@/views/scss/about-us.scss'
  export default{
    name: "AboutUs",
    data () {
      return {
          bannerList:[]
      }
    },
      created() {
          this.Interface()
      },
   methods: {
       async Interface() {
           //公司信息
           await this.$axios.post('pcapi/index/banner',{
               type:2
           }).then(res => {
               if (res.data.code == 1) {
                   this.bannerList = res.data.data
               }
           })
       },
    }

  }
</script>
<style>
</style>
